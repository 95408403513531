// const GOOGLE_TYPES_ADDREESS_ENUM = {
//   street_number: 'street_number',
//   route: 'route',
//   postal_code: 'postal_code',
//   locality: 'locality',
//   country: 'country',
//   administrative_area_level_1: 'administrative_area_level_1',
//   administrative_area_level_2: 'administrative_area_level_2',
// };

/**
 * Formats results from google maps into an object we can save to the wordpress site database
 * @param addressData from google maps
 * @param placeResultData from google maps
 * @param xFindRegionFromZip a function that can be used to lookup a region using zip code
 */
const getWPAddressData = function (address, placeResultData, xFindLocationFromZip, xFindRegionFromLocationAndZip) {
  // console.log("address data", address);
  // console.log("place result data", placeResultData);
  let region = null
  const location = xFindLocationFromZip(address?.postal_code)
  console.log('Looking for location for google address', address?.postal_code, location)
  if (location) {
    region = xFindRegionFromLocationAndZip(location, address?.postal_code)
    console.log('location found, looking for region', region)
  }

  console.log('Region is ', region)

  if (address?.postal_code && region && location) {
    return {
      address: (() => {
        let ret = ''
        if (address?.street_number) {
          ret += `${address.street_number} `
        }

        if (address?.route) {
          ret += address.route
        }

        return ret
      })(),
      address_2: null,
      city: address?.locality,
      state: address?.administrative_area_level_1,
      zip: address?.postal_code,
      region: region.name,
      location_id: location.id
    }
  } else {
    console.error('error making wp address', { address, region, location })
  }

  return null
}

export default {
  getWPAddressData
}
